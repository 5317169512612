import { BottomTabNavigationProp, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, RouteProp, CompositeNavigationProp } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as React from 'react';
import { Pressable } from 'react-native';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import ReportScreen from '../screens/ReportScreen';
import MeBidsScreen from '../screens/MeBidsScreen';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import MeBidScreen from '../screens/MeBidScreen';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { Box, Button, Menu } from 'native-base';
import CreateBuyBidScreen from '../screens/CreateBuyBidScreen';
import CreateBidAdjustmentScreen from '../screens/CreateBidAdjustmentScreen';
import useAuth from '../hooks/useAuth';
import SigninScreen from '../screens/SigninScreen';
import RegisterScreen from '../screens/RegisterScreen';
import { useActionSheet, ActionSheetOptions } from '@expo/react-native-action-sheet';
import Layout from '../constants/Layout';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem, DrawerItemList } from '@react-navigation/drawer';
const Stack = createNativeStackNavigator<RootStackParamList>();

const navTheme = DefaultTheme;
navTheme.colors.background = '#fff';

const onOpenActionSheet = (
  showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i?: number) => void | Promise<void>) => void,
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<RootTabParamList, 'MeBids'>,
    NativeStackNavigationProp<RootStackParamList, 'MeBid'>
  >,
) => {
  const selectOptions = ['新增買入', '新增款項', '取消'];
  // const destructiveButtonIndex = 0;
  const cancelButtonIndex = 2;

  showActionSheetWithOptions(
    {
      options: selectOptions,
      cancelButtonIndex,
      // destructiveButtonIndex,
    },
    buttonIndex => {
      if (buttonIndex === 0) {
        navigation.navigate('CreateBuyBid');
      } else if (buttonIndex === 1) {
        navigation.navigate('CreateBidAdjustment');
      } else if (buttonIndex === 2) {
        return;
      } else {
        throw new Error('Unknown button index.');
      }
    },
  );
};

function Navigation() {
  const { authData } = useAuth();

  return (
    <NavigationContainer linking={LinkingConfiguration} theme={navTheme}>
      {authData ? (
        <Stack.Navigator>
          <Stack.Screen name="Root" component={DrawerTabNavigator} options={{ headerShown: false }} />
          <Stack.Screen
            name="MeBid"
            component={MeBidScreen}
            options={{ title: '股票詳情', headerTitleAlign: 'center' }}
          />
          <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
          <Stack.Screen name="Modal" component={ModalScreen} />
          <Stack.Screen
            name="CreateBuyBid"
            component={CreateBuyBidScreen}
            options={{ title: '新增買入', headerTitleAlign: 'center' }}
          />
          <Stack.Screen
            name="CreateBidAdjustment"
            component={CreateBidAdjustmentScreen}
            options={{ title: '新增款項', headerTitleAlign: 'center' }}
          />
          {/* <Stack.Group screenOptions={{ presentation: 'modal' }}>
          <Stack.Screen name="Modal" component={ModalScreen} />
        </Stack.Group> */}
        </Stack.Navigator>
      ) : (
        <Stack.Navigator>
          <Stack.Screen name="Signin" component={SigninScreen} options={{ title: '登入' }} />
          <Stack.Screen name="Register" component={RegisterScreen} options={{ title: '註冊' }} />
        </Stack.Navigator>
      )}
    </NavigationContainer>
  );
}

export default Navigation;

const Drawer = createDrawerNavigator();

function DrawerTabNavigator() {
  const { showActionSheetWithOptions } = useActionSheet();
  const { signOut } = useAuth();

  return (
    <Drawer.Navigator
      initialRouteName="MeBids"
      drawerContent={props => {
        return (
          <DrawerContentScrollView>
            <DrawerItemList {...props} />
            <DrawerItem label="登出" onPress={() => signOut()} />
          </DrawerContentScrollView>
        );
      }}
    >
      <Drawer.Screen
        name="MeBids"
        component={MeBidsScreen}
        options={({ navigation }) => ({
          title: '我的股票',
          headerTitleAlign: 'center',
          headerRight: () => (
            <Box mr={Layout.fixMx}>
              <Pressable
                accessibilityLabel="More options menu"
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}
                onPress={() => onOpenActionSheet(showActionSheetWithOptions, navigation)}
              >
                <Ionicons name="add-outline" size={30} style={{ color: '#0088CC' }} />
              </Pressable>
            </Box>
          ),
        })}
      />
    </Drawer.Navigator>
  );
}

const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const { signOut } = useAuth();
  const { showActionSheetWithOptions } = useActionSheet();

  return (
    <BottomTab.Navigator initialRouteName="MeBids">
      <BottomTab.Screen
        name="MeBids"
        component={MeBidsScreen}
        options={({ navigation }: RootTabScreenProps<'MeBids'>) => ({
          title: '我的股票',
          headerTitleAlign: 'center',
          tabBarIcon: ({ color }) => <TabBarIcon name="trending-up-outline" color={color} />,
          headerLeft: () => (
            <Box>
              <Button variant="unstyled" onPress={() => signOut()}>
                登出
              </Button>
            </Box>
          ),
          headerRight: () => (
            <Box mr={Layout.fixMx}>
              <Pressable
                accessibilityLabel="More options menu"
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}
                onPress={() => onOpenActionSheet(showActionSheetWithOptions, navigation)}
              >
                <Ionicons name="add-outline" size={30} style={{ color: '#0088CC' }} />
              </Pressable>
            </Box>
          ),
        })}
      />
      <BottomTab.Screen
        name="Report"
        component={ReportScreen}
        options={({ navigation }: RootTabScreenProps<'Report'>) => ({
          title: '報告',
          headerTitleAlign: 'center',
          tabBarIcon: ({ color }) => <TabBarIcon name="bar-chart-outline" color={color} />,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}
            >
              <FontAwesome name="info-circle" size={25} style={{ marginRight: 15 }} />
            </Pressable>
          ),
        })}
      />
    </BottomTab.Navigator>
  );
}

function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
  // return <FontAwesome size={20} style={{ marginBottom: -3 }} {...props} />;
  return <Ionicons size={20} style={{ marginBottom: -3 }} {...props} />;
}
