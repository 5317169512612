import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Center, FormControl, Input, Modal, useToast, WarningOutlineIcon } from 'native-base';
import React, { useEffect, useState } from 'react';
import {
  CreateSellBidDocument,
  CreateSellBidInput,
  CreateSellBidMutation,
  CreateSellBidMutationVariables,
} from '../generated';
import * as Yup from 'yup';
import { Keyboard, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import WebDatePicker from '../components/WebDatePicker';
import { format } from 'date-fns';
import Layout from '../constants/Layout';

type ShowModal = {
  show: boolean;
  bidId: string;
  buyItemId: string;
};

type Props = {
  showModal: ShowModal;
  setShowModal: ({}: ShowModal) => void;
};

const CreateSellBidSchema = Yup.object().shape({
  date: Yup.date(),
  tradingFee: Yup.number().required('請輸入手續費'),
  items: Yup.array().of(
    Yup.object().shape({
      buyItemId: Yup.string(),
      price: Yup.number().min(1).required('請輸入價錢'),
      quantity: Yup.number().min(1).required('請輸入股數'),
    }),
  ),
});

const SellBidItemModal = ({ showModal: { show, bidId, buyItemId }, setShowModal }: Props) => {
  const [date, setDate] = useState(new Date());
  const toast = useToast();
  const [createSellBid, { loading, error }] = useMutation<CreateSellBidMutation, CreateSellBidMutationVariables>(
    CreateSellBidDocument,
  );

  const onChange = (event: any, selectedDate: Date | undefined) => {
    const currentDate = selectedDate || date;
    setDate(currentDate);
  };

  const webOnChange = (event: any) => {
    const currentDate = event.target.value || date;
    setDate(currentDate);
  };

  useEffect(() => {
    if (error) {
      toast.show({
        title: '賣出失敗，請再嘗試',
        placement: 'top',
        status: 'error',
      });
    }
  }, [error]);

  return (
    <Formik<CreateSellBidInput>
      initialValues={{
        tradingFee: 0,
        items: [{ buyItemId: '', price: 0, quantity: 0 }],
      }}
      validationSchema={CreateSellBidSchema}
      onSubmit={async ({ tradingFee, items }) => {
        Keyboard.dismiss();
        try {
          await createSellBid({
            variables: {
              id: bidId,
              input: {
                date: new Date(date),
                tradingFee: parseFloat(String(tradingFee)),
                items: [
                  {
                    buyItemId,
                    price: parseFloat(String(items[0].price)),
                    quantity: parseFloat(String(items[0].quantity)),
                  },
                ],
              },
            },
          });
          toast.show({
            title: '賣出成功',
            placement: 'top',
            status: 'success',
          });
          setShowModal({ show: false, bidId: '', buyItemId: '' });
        } catch (e) {}
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => {
        return (
          <Center>
            <Modal isOpen={show} onClose={() => setShowModal({ show: false, bidId: '', buyItemId: '' })}>
              <Modal.Content maxWidth="400px">
                <Modal.CloseButton />
                <Modal.Header>賣出股票</Modal.Header>
                <Modal.Body>
                  <FormControl isRequired>
                    <FormControl.Label>日期</FormControl.Label>
                    <DateTimePicker
                      testID="dateTimePicker"
                      value={date}
                      mode="date"
                      is24Hour={true}
                      display="default"
                      onChange={onChange}
                      style={{ width: 320 }}
                    />

                    {Platform.OS !== 'android' && Platform.OS !== 'ios' && (
                      <WebDatePicker value={format(new Date(date), 'yyyy-MM-dd')} onChange={webOnChange} />
                    )}
                  </FormControl>
                  <FormControl isRequired isInvalid={touched.items?.[0]?.price && !values.items?.[0]?.price}>
                    <FormControl.Label>價錢</FormControl.Label>
                    <Input
                      onChangeText={handleChange('items.0.price')}
                      onBlur={handleBlur('items.0.price')}
                      value={values.items?.[0]?.price ? `${values.items?.[0]?.price}` : ''}
                      keyboardType={Layout.isWebDevice ? 'default' : 'numeric'}
                      placeholder="價錢"
                      borderWidth={2}
                      size="md"
                    />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                      請輸入價錢
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={touched.items?.[0]?.quantity && !values.items?.[0]?.quantity}>
                    <FormControl.Label>股數</FormControl.Label>
                    <Input
                      onChangeText={handleChange('items.0.quantity')}
                      onBlur={handleBlur('items.0.quantity')}
                      value={values.items?.[0]?.quantity ? `${values.items?.[0]?.quantity}` : ''}
                      keyboardType={Layout.isWebDevice ? 'default' : 'numeric'}
                      placeholder="股數"
                      borderWidth={2}
                      size="md"
                    />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                      請輸入股數
                    </FormControl.ErrorMessage>
                  </FormControl>

                  <FormControl isRequired isInvalid={touched.tradingFee && errors.tradingFee ? true : false}>
                    <FormControl.Label>交易費</FormControl.Label>
                    <Input
                      onChangeText={handleChange('tradingFee')}
                      onBlur={handleBlur('tradingFee')}
                      value={values.tradingFee ? `${values.tradingFee}` : ''}
                      keyboardType={Layout.isWebDevice ? 'default' : 'numeric'}
                      placeholder="交易費"
                      borderWidth={2}
                      size="md"
                    />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                      {errors.tradingFee}
                    </FormControl.ErrorMessage>
                  </FormControl>
                </Modal.Body>
                <Modal.Footer>
                  <Button.Group space={2}>
                    <Button
                      variant="ghost"
                      colorScheme="blueGray"
                      onPress={() => {
                        setShowModal({ show: false, bidId: '', buyItemId: '' });
                      }}
                    >
                      取消
                    </Button>
                    <Button isLoading={loading} onPress={handleSubmit as any}>
                      確定
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </Center>
        );
      }}
    </Formik>
  );
};

export default SellBidItemModal;
