export type Options = {
  significantDigits?: number;
  thousandsSeparator?: string;
  decimalSeparator?: string;
  symbol?: string;
};

const currencyFormatter = (value: number, options?: Options) => {
  const _option = {
    significantDigits: 0,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '',
    ...options,
  };

  if (_option.significantDigits <= 0) {
    return `${_option.symbol}${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, _option.thousandsSeparator)}`;
  }

  const _value = value.toFixed(_option.significantDigits);
  const [currency, decimal] = _value.split('.');

  return `${_option.symbol}${currency.replace(/\B(?=(\d{3})+(?!\d))/g, _option.thousandsSeparator)}${
    _option.decimalSeparator
  }${decimal}`;
};
export default currencyFormatter;
