import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { Box, Button, HStack, Text, VStack } from 'native-base';
import React, { memo, useState } from 'react';
import { Alert, Platform } from 'react-native';

import Layout from '../constants/Layout';
import {
  BidItemType,
  MeBidQuery,
  RemoveBidItemDocument,
  RemoveBidItemMutation,
  RemoveBidItemMutationVariables,
} from '../generated';
import currencyFormatter from '../utils/currencyFormatter';
import SellBidItemModal from './SellBidItemModal';

type Props = {
  bid: NonNullable<NonNullable<MeBidQuery['me']>['bid']>;
};

const MeBidItem = ({ bid }: Props) => {
  const [showModal, setShowModal] = useState({
    show: false,
    bidId: '',
    buyItemId: '',
  });
  const [removeBidItem, { loading: r_loading, error: r_error }] = useMutation<
    RemoveBidItemMutation,
    RemoveBidItemMutationVariables
  >(RemoveBidItemDocument);

  return (
    <>
      <SellBidItemModal showModal={showModal} setShowModal={setShowModal} />
      <Box py="2" mb="2" rounded="lg">
        <HStack justifyContent="space-between">
          <Text fontSize="xs" color="gray.500">
            類別
          </Text>
          <Text fontSize="xs" color="gray.500">
            日期
          </Text>
          <Text fontSize="xs" color="gray.500">
            股數/價錢
          </Text>
          <Text fontSize="xs" color="gray.500">
            總值
          </Text>
          <Text fontSize="xs" color="gray.500">
            行動
          </Text>
        </HStack>
      </Box>
      <VStack space={3}>
        {bid.items.nodes ? (
          bid.items.nodes.map(({ id, balance, quantity, createdAt, price, totalAmount, type }) => (
            <Box key={id}>
              <HStack justifyContent="space-between" alignItems="center">
                <Text color={type === BidItemType.Buy ? 'green.600' : 'error.600'}>
                  {type === BidItemType.Buy ? (balance ?? 0 > 0 ? '買入' : '沽清') : '賣出'}
                </Text>
                <Text>{format(new Date(createdAt), 'dd/MM/yyyy')}</Text>
                <VStack>
                  <Text>{type === BidItemType.Buy ? balance ?? '沒有股數' : quantity}</Text>
                  <Text>{currencyFormatter(price)}</Text>
                </VStack>

                <Text>{currencyFormatter(totalAmount)}</Text>

                <VStack>
                  {type === BidItemType.Buy && (balance ?? 0) > 0 && (
                    <Button
                      variant="link"
                      onPress={() => {
                        setShowModal({ show: true, bidId: bid.id, buyItemId: id });
                      }}
                    >
                      賣出
                    </Button>
                  )}

                  {((balance ?? 0) > 0 || quantity > 0) && (
                    <Button
                      variant="link"
                      isLoading={r_loading}
                      onPress={async () => {
                        // Platform.OS
                        if (Platform.OS === 'android' || Platform.OS === 'ios') {
                          return Alert.alert(
                            `刪除`,
                            `${type === BidItemType.Buy ? '買入' : '賣出'}: ${
                              type === BidItemType.Buy ? balance : quantity
                            }股`,
                            [
                              {
                                text: '取消',
                              },
                              { text: '確定', onPress: async () => removeBidItem({ variables: { id } }) },
                            ],
                          );
                        } else {
                          if (
                            confirm(
                              `刪除 ${type === BidItemType.Buy ? '買入' : '賣出'}: ${
                                type === BidItemType.Buy ? balance : quantity
                              }股`,
                            ) == true
                          ) {
                            await removeBidItem({ variables: { id } });
                          }
                        }
                      }}
                    >
                      刪除
                    </Button>
                  )}
                </VStack>
              </HStack>
            </Box>
          ))
        ) : (
          <Box w={Layout.fixWidth}>
            <Text size={30} w="100%" textAlign="center">
              沒有資料
            </Text>
          </Box>
        )}
      </VStack>
    </>
  );
};

export default memo(MeBidItem);
