import { RootStackScreenProps } from '../types';
import React from 'react';
import { Box, VStack, FormControl, WarningOutlineIcon, Input, Button, Heading, useToast } from 'native-base';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Layout from '../constants/Layout';
import useAuth from '../hooks/useAuth';
import { RegisterInput } from '../generated';
import useToastError from '../hooks/useToastError';
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native';

const RegisterSchema: Yup.SchemaOf<RegisterInput> = Yup.object().shape({
  name: Yup.string().required('請先輸入名稱'),
  email: Yup.string().email('請輸入有效電郵').required('請先輸入電郵'),
  password: Yup.string().required('請先輸入密碼'),
  phoneNumber: Yup.string()
    .matches(/^1[0-9]{10}$|^[569][0-9]{7}$/, '請輸入有效電話號碼, e.g. 55555555')
    .required('請先輸入電話'),
  metadata: Yup.array(),
});

const RegisterScreen = ({ navigation }: RootStackScreenProps<'Register'>) => {
  const { loading, register, error } = useAuth();
  const toast = useToast();
  useToastError(error);

  return (
    <TouchableWithoutFeedback
      onPress={() => (Platform.OS === 'ios' || Platform.OS === 'android' ? Keyboard.dismiss() : undefined)}
    >
      <Box w="100%" justifyContent="center" alignItems="center">
        <Heading my="10">立即登記</Heading>
        <Formik<RegisterInput>
          initialValues={{
            email: '',
            password: '',
            phoneNumber: '',
            name: '',
          }}
          validationSchema={RegisterSchema}
          onSubmit={async input => {
            try {
              await register({ input: { ...input, phoneNumber: `+852${input.phoneNumber}` } });
              toast.show({
                title: '註冊成功',
                placement: 'top',
                status: 'success',
              });
              navigation.navigate('Signin');
            } catch (e) {}
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <VStack space={5} w={Layout.fixWidth}>
              <FormControl isRequired isInvalid={touched.email && errors.email ? true : false}>
                <FormControl.Label>電郵</FormControl.Label>
                <Input
                  placeholder="輸入電郵"
                  borderWidth={2}
                  size="md"
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.email}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={touched.password && errors.password ? true : false}>
                <FormControl.Label>密碼</FormControl.Label>
                <Input
                  placeholder="輸入密碼"
                  borderWidth={2}
                  size="md"
                  type="password"
                  onChangeText={handleChange('password')}
                  onBlur={handleBlur('password')}
                  value={values.password}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.password}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl isInvalid={touched.name && errors.name ? true : false}>
                <FormControl.Label>名稱</FormControl.Label>
                <Input
                  placeholder="輸入名稱"
                  borderWidth={2}
                  size="md"
                  onChangeText={handleChange('name')}
                  onBlur={handleBlur('name')}
                  value={values.name ?? ''}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.name}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl isInvalid={touched.phoneNumber && errors.phoneNumber ? true : false}>
                <FormControl.Label>電話</FormControl.Label>
                <Input
                  placeholder="輸入電話"
                  borderWidth={2}
                  size="md"
                  onChangeText={handleChange('phoneNumber')}
                  onBlur={handleBlur('phoneNumber')}
                  value={values.phoneNumber}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.phoneNumber}
                </FormControl.ErrorMessage>
              </FormControl>

              <Button isLoading={loading} onPress={handleSubmit as any}>
                註冊
              </Button>
              <Button isDisabled={loading} onPress={() => navigation.navigate('Signin')} variant="outline">
                取消
              </Button>
            </VStack>
          )}
        </Formik>
      </Box>
    </TouchableWithoutFeedback>
  );
};

export default RegisterScreen;
