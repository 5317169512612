import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

const useGetApolloClient = () => {
  const [client, setClient] = useState<ApolloClient<any>>();

  const httpLink = createHttpLink({
    uri: 'https://888stock-v2-api.billjjjj.myds.me/graphql',
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await AsyncStorage.getItem('token');
    if (!token) return;

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  useEffect(() => {
    try {
      if (!client) {
        const newClient = new ApolloClient({
          cache: new InMemoryCache(),
          link: authLink.concat(httpLink),
        });
        setClient(newClient);
      }
    } catch (e) {
      console.warn(e);
    }
  }, []);

  return client;
};

export default useGetApolloClient;
