import { Dimensions, Platform } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const isWebDevice = Platform.OS === 'web';

export default {
  window: {
    width,
    height,
  },
  fixWidth: 375,
  fixMx: 5,
  isSmallDevice: width < 375,
  isWebDevice,
};
