import { useQuery } from '@apollo/client';
import { MeBidDocument, MeBidQueryVariables } from '../generated';

const useMeBid = (variables: MeBidQueryVariables) => {
  const { data, loading, error, refetch } = useQuery(MeBidDocument, {
    variables,
    pollInterval: 1000 * 60 * 3, // 3 minutes
  });

  return { data, loading, error, refetch };
};

export default useMeBid;
