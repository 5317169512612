import { Box, Divider, HStack, Text, VStack } from 'native-base';
import React from 'react';
import Layout from '../constants/Layout';
import { MeBidsQuery } from '../generated';
import currencyFormatter from '../utils/currencyFormatter';
import ProfitText from './ProfitText';

type Input = {
  me?: MeBidsQuery['me'];
};

const MeBidsHeader = ({ me }: Input) => {
  return (
    <Box width={Layout.fixWidth} maxW="100%">
      <HStack justifyContent="space-between">
        <VStack>
          <HStack>
            <Text color="gray.400">成本: </Text>
            <Text>{currencyFormatter(me?.totalCost ?? 0)}</Text>
          </HStack>
          <HStack>
            <Text color="gray.400">現值: </Text>
            <Text>{currencyFormatter(me?.totalPrice ?? 0)}</Text>
          </HStack>
          <HStack>
            <Text color="gray.400">利息: </Text>
            <Text>{currencyFormatter(me?.totalDividend ?? 0)}</Text>
          </HStack>
        </VStack>
        <Divider orientation="vertical" mx="3" />
        <VStack>
          <Text color="gray.400" textAlign="right">
            總參考盈虧
          </Text>
          <ProfitText
            textAlign="right"
            value={me?.totalBookProfit ?? 0}
            fontSize={25}
            fontWeight={600}
            options={{ significantDigits: 2 }}
          />
        </VStack>
      </HStack>
    </Box>
  );
};

export default MeBidsHeader;
