import { Box, HStack, Text, VStack } from 'native-base';
import React from 'react';
import Layout from '../constants/Layout';
import { MeBidQuery } from '../generated';
import currencyFormatter from '../utils/currencyFormatter';
import ProfitText from './ProfitText';

type Props = {
  bid: NonNullable<MeBidQuery['me']>['bid'];
};

const MeBidHeader = ({ bid }: Props) => {
  return (
    <>
      {bid ? (
        <Box w={Layout.fixWidth}>
          <HStack justifyContent="center" py="5">
            <Text fontSize={20} color="white">
              {bid.stock.code} {bid.stock.name}
            </Text>
          </HStack>
          <VStack space={2}>
            <HStack justifyContent="space-between">
              <VStack>
                <Text fontSize="xs" color="gray.400">
                  總持股數
                </Text>
                <Text fontSize="2xl" color="white">
                  {currencyFormatter(bid.totalBalance)}
                </Text>
              </VStack>

              <VStack alignItems="flex-end">
                <Text fontSize="xs" color="gray.400">
                  總持股數盈利/虧損
                </Text>
                <ProfitText value={bid.bookProfit} fontSize="2xl" />
              </VStack>
            </HStack>
            <HStack justifyContent="space-between">
              <VStack>
                <Text fontSize="xs" color="gray.400">
                  平均買入價
                </Text>
                <Text color="white">{currencyFormatter(bid.avgBuyPrice)}</Text>
              </VStack>
              <VStack alignItems="flex-end">
                <Text fontSize="xs" color="gray.400">
                  總股息
                </Text>
                <Text color="white">--</Text>
              </VStack>
            </HStack>

            <HStack justifyContent="space-between">
              <VStack>
                <Text fontSize="xs" color="gray.400">
                  平均賣出價
                </Text>
                <Text color="white">{currencyFormatter(bid.avgSellPrice)}</Text>
              </VStack>
            </HStack>
          </VStack>
        </Box>
      ) : (
        <Box w={Layout.fixWidth}>
          <Text size={30} w="100%" textAlign="center">
            沒有資料
          </Text>
        </Box>
      )}
    </>
  );
};

export default MeBidHeader;
