import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  CreateBuyBidDocument,
  CreateBuyBidInput,
  CreateBuyBidMutation,
  CreateBuyBidMutationVariables,
} from '../generated';
import { Box, Button, Input, useToast, VStack, Text, FormControl, WarningOutlineIcon, HStack } from 'native-base';
import { Formik } from 'formik';
import { RootStackScreenProps } from '../types';
import Layout from '../constants/Layout';
import { useMutation } from '@apollo/client';
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import WebDatePicker from '../components/WebDatePicker';
import { format } from 'date-fns';
import useStock from '../hooks/useStock';
import currencyFormatter from '../utils/currencyFormatter';

const CreateBuyBidSchema: Yup.SchemaOf<CreateBuyBidInput> = Yup.object().shape({
  date: Yup.date().required('請選擇日期'),
  tradingFee: Yup.number().required('請輸入手續費'),
  quoteCode: Yup.string().required('請輸入號碼'),
  items: Yup.array()
    .min(1, '最小購買一手')
    .of(
      Yup.object().shape({
        price: Yup.number().min(0).required('請輸入價錢'),
        quantity: Yup.number().min(1).required('請輸入股數'),
      }),
    ),
});

const CreateBuyBidScreen = ({ navigation }: RootStackScreenProps<'CreateBuyBid'>) => {
  const [createBuyBid, { loading, error }] = useMutation<CreateBuyBidMutation, CreateBuyBidMutationVariables>(
    CreateBuyBidDocument,
  );
  const { data: stockData, setCode, loading: stockLoading } = useStock();
  const toast = useToast();
  const [date, setDate] = useState(new Date());
  const onChange = (event: any, selectedDate: Date | undefined) => {
    const currentDate = selectedDate || date;
    setDate(currentDate);
  };

  const webOnChange = (event: any) => {
    const currentDate = event.target.value || date;
    setDate(currentDate);
  };

  useEffect(() => {
    if (error) {
      toast.show({
        title: '新增失敗，請再嘗試',
        placement: 'top',
        status: 'error',
      });
    }
  }, [error]);

  return (
    <TouchableWithoutFeedback
      onPress={() => (Platform.OS === 'ios' || Platform.OS === 'android' ? Keyboard.dismiss() : undefined)}
    >
      <Box w="100%" justifyContent="center" alignItems="center" mt="5">
        <Formik<CreateBuyBidInput>
          initialValues={{
            date: new Date(),
            tradingFee: 0,
            quoteCode: '',
            items: [],
          }}
          validationSchema={CreateBuyBidSchema}
          onSubmit={async ({ tradingFee, quoteCode, items }) => {
            try {
              await createBuyBid({
                variables: {
                  input: {
                    date: new Date(date),
                    quoteCode,
                    items: [
                      {
                        price: parseFloat(String(items[0].price)),
                        quantity: parseFloat(String(items[0].quantity)),
                      },
                    ],
                    tradingFee: parseFloat(String(tradingFee)),
                  },
                },
              });
              toast.show({
                title: '新增成功',
                placement: 'top',
                status: 'success',
              });
              navigation.navigate('Root');
            } catch (e) {}
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, setFieldValue, touched }) => {
            return (
              <VStack space={5} w={Layout.fixWidth}>
                <VStack justifyContent="space-between" borderWidth="1" rounded="5" p="5" space="5">
                  <HStack>
                    <Text w="1/2">
                      股票名稱:
                      {stockLoading && values.quoteCode !== '' ? 'Loading...' : stockData?.stock?.name ?? ' --'}
                    </Text>
                    <Text w="1/2">
                      每手股數: {stockLoading && values.quoteCode !== '' ? 'Loading...' : stockData?.stock?.id ?? ' --'}
                    </Text>
                  </HStack>

                  <Text>
                    總數:
                    {values.items.length && values.items[0].price && values.items[0].quantity
                      ? ` $${currencyFormatter(
                          +values.items[0].price * +values.items[0].quantity + +values.tradingFee,
                        )}`
                      : 0}
                  </Text>
                </VStack>

                <FormControl isRequired>
                  <FormControl.Label>日期</FormControl.Label>
                  <DateTimePicker
                    testID="dateTimePicker"
                    value={date}
                    mode="date"
                    is24Hour={true}
                    display="default"
                    onChange={onChange}
                    style={{ width: 320 }}
                  />

                  {Platform.OS !== 'android' && Platform.OS !== 'ios' && (
                    <WebDatePicker value={format(new Date(date), 'yyyy-MM-dd')} onChange={webOnChange} />
                  )}
                </FormControl>

                <FormControl isRequired isInvalid={touched.quoteCode && errors.quoteCode ? true : false}>
                  <FormControl.Label>股票號碼</FormControl.Label>
                  <Input
                    onChangeText={value => {
                      setFieldValue('quoteCode', `${value !== '' && value !== '.' ? parseInt(value) : ''}`);
                      setCode(value);
                    }}
                    onBlur={handleBlur('quoteCode')}
                    value={values.quoteCode}
                    keyboardType="numeric"
                    placeholder="股票號碼"
                    w={Layout.fixWidth}
                    borderWidth={2}
                    size="md"
                    maxLength={5}
                    focusable={true}
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    {errors.quoteCode}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={touched.items?.[0]?.quantity && !values.items?.[0]?.quantity}>
                  <FormControl.Label>股數</FormControl.Label>
                  <Input
                    onChangeText={handleChange('items.0.quantity')}
                    onBlur={handleBlur('items.0.quantity')}
                    value={values.items?.[0]?.quantity ? `${values.items?.[0]?.quantity}` : ''}
                    keyboardType="numeric"
                    placeholder="股數"
                    w={Layout.fixWidth}
                    borderWidth={2}
                    size="md"
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    請輸入股數
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={touched.items?.[0]?.price && !values.items?.[0]?.price}>
                  <FormControl.Label>價錢</FormControl.Label>
                  <Input
                    onChangeText={handleChange('items.0.price')}
                    onBlur={handleBlur('items.0.price')}
                    value={values.items?.[0]?.price ? `${values.items?.[0]?.price}` : ''}
                    keyboardType={Layout.isWebDevice ? 'default' : 'numeric'}
                    placeholder="價錢"
                    w={Layout.fixWidth}
                    borderWidth={2}
                    size="md"
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    請輸入價錢
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={touched.tradingFee && errors.tradingFee ? true : false}>
                  <FormControl.Label>交易費</FormControl.Label>
                  <Input
                    onChangeText={handleChange('tradingFee')}
                    onBlur={handleBlur('tradingFee')}
                    value={values.tradingFee ? `${values.tradingFee}` : ''}
                    keyboardType={Layout.isWebDevice ? 'default' : 'numeric'}
                    placeholder="交易費"
                    w={Layout.fixWidth}
                    borderWidth={2}
                    size="md"
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    {errors.tradingFee}
                  </FormControl.ErrorMessage>
                </FormControl>

                <Button isLoading={loading} onPress={handleSubmit as any}>
                  確定
                </Button>
                <Button isDisabled={loading} onPress={() => navigation.navigate('Root')} variant="outline">
                  取消
                </Button>
              </VStack>
            );
          }}
        </Formik>
      </Box>
    </TouchableWithoutFeedback>
  );
};

export default CreateBuyBidScreen;
