import { useQuery } from '@apollo/client';
import { MeBidsDocument, MeBidsQueryVariables } from '../generated';

const useMeBids = (variables?: MeBidsQueryVariables) => {
  const { data, loading, error, refetch } = useQuery(MeBidsDocument, {
    variables: variables ?? { take: 100 },
    pollInterval: 1000 * 60 * 3, // 3 minutes
  });

  return { data, loading, error, refetch };
};

export default useMeBids;
