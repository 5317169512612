import React from 'react';
import { Box, ITextProps, Text } from 'native-base';
import currencyFormatter, { Options } from '../utils/currencyFormatter';

type Props = {
  value: number;
  options?: Partial<Options>;
};

const ProfitText = ({ value, options, ...props }: Props & ITextProps) => {
  return (
    <Box>
      {value >= 0 ? (
        <Text color="success.600" {...props}>
          +{currencyFormatter(value, options)}
        </Text>
      ) : (
        <Text color="danger.600" {...props}>
          {currencyFormatter(value, options)}
        </Text>
      )}
    </Box>
  );
};

export default ProfitText;
