import { MeBidsQuery } from '../generated';
import { RootTabScreenProps } from '../types';
import React, { useEffect, useState } from 'react';
import { Box, VStack, Center, View, Input } from 'native-base';
import ScreenSkeleton from '../components/ScreenSkeleton';
import MeBidsItem from '../components/MeBidsItem';
import useMeBids from '../hooks/useMeBids';
import MeBidsHeader from '../components/MeBidsHeader';
import Layout from '../constants/Layout';

export default function MeBidsScreen({ navigation }: RootTabScreenProps<'MeBids'>) {
  const { data, error, loading } = useMeBids();
  const [filteredMeBids, setFilteredMeBids] = useState<NonNullable<MeBidsQuery['me']>['bids']['nodes']>([]);
  const [searchCode, setSearchCode] = useState('');

  useEffect(() => {
    const bids = data?.me?.bids.nodes;
    if (searchCode === '') {
      setFilteredMeBids([]);
    }
    if (bids && searchCode) {
      const _filterd = bids.filter(bid => bid.stock.code.includes(searchCode));
      setFilteredMeBids(_filterd);
    }
  }, [data, searchCode]);

  return (
    <Box h="100%">
      {loading ? (
        <View mx={Layout.fixMx}>
          <ScreenSkeleton />
        </View>
      ) : (
        <VStack space={5}>
          <Center bg="gray.100" px="5" py="5">
            <MeBidsHeader me={data?.me} />
          </Center>
          <Box alignItems="center">
            <Input
              mx="3"
              value={searchCode}
              onChangeText={text => setSearchCode(text)}
              keyboardType="numeric"
              placeholder="搜尋(股票代號)"
              w={Layout.fixWidth}
              borderWidth={3}
              size="md"
            />
          </Box>

          <Center>
            <MeBidsItem bids={filteredMeBids.length ? filteredMeBids : data?.me?.bids.nodes} />
          </Center>
        </VStack>
      )}
    </Box>
  );
}
