import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { MeBidsDocument, MeBidsQueryVariables, StockDocument } from '../generated';

const useStock = () => {
  const [code, setCode] = useState("")

  const { data, loading, error, refetch } = useQuery(StockDocument, {
    variables: { code },
  });

  return { data, loading, error, refetch, setCode };
};

export default useStock;
