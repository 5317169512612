import { ApolloProvider } from '@apollo/client';
import useGetApolloClient from './hooks/useGetApolloClient';
import { StatusBar } from 'expo-status-bar';
import { NativeBaseProvider } from 'native-base';
import theme from './theme';
import React from 'react';
import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation';
import AuthProvider from './providers/AuthProvider';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { SSRProvider } from '@react-aria/ssr';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const client = useGetApolloClient();

  if (!isLoadingComplete || !client) {
    return null;
  } else {
    return (
      <SSRProvider>
        <ApolloProvider client={client}>
          <NativeBaseProvider theme={theme}>
            <ActionSheetProvider>
              <AuthProvider>
                <>
                  <StatusBar />
                  <Navigation />
                </>
              </AuthProvider>
            </ActionSheetProvider>
          </NativeBaseProvider>
        </ApolloProvider>
      </SSRProvider>
    );
  }
}
