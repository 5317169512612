import React from 'react';
import type { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../types';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useMeBid from '../hooks/useMeBid';
import { Box, ScrollView, View, Text, Center } from 'native-base';
import ScreenSkeleton from '../components/ScreenSkeleton';
import useToastError from '../hooks/useToastError';
import { BidItemType, FilterOperator, SortOrder } from '../generated';
import Layout from '../constants/Layout';
import MeBidItem from '../components/MeBidItem';
import MeBidHeader from '../components/MeBidHeader';

type Props = {
  route: RouteProp<RootStackParamList, 'MeBid'>;
  navigation: NativeStackNavigationProp<RootStackParamList, 'MeBid'>;
};

const MeBidScreen = ({ route, navigation }: Props) => {
  const { data, loading, error } = useMeBid({
    id: route.params.bidId,
    take: 9999,
    skip: 0,
    sorter: [{ field: 'createdAt', order: SortOrder.Desc }],
    filter: [{ key: 'type', filterValue: { operator: FilterOperator.In, value: [BidItemType.Buy, BidItemType.Sell] } }],
  });

  useToastError(error);

  return (
    <Box h="100%">
      {loading ? (
        <View mx={Layout.fixMx}>
          <ScreenSkeleton />
        </View>
      ) : (
        <ScrollView>
          <Box pb="5" bg="darkBlue.800">
            <Center>
              <MeBidHeader bid={data?.me?.bid} />
            </Center>
          </Box>

          {data?.me?.bid ? (
            <Box mx={Layout.fixMx}>
              <MeBidItem bid={data.me.bid} />
            </Box>
          ) : (
            <Box>
              <Text>加載中...</Text>
            </Box>
          )}
        </ScrollView>
      )}
    </Box>
  );
};

export default MeBidScreen;
