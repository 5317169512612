import { useNavigation } from '@react-navigation/native';
import { Box, HStack, VStack, Text, Pressable } from 'native-base';
import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import Layout from '../constants/Layout';
import { MeBidsQuery, RemoveBidDocument, RemoveBidMutation, RemoveBidMutationVariables } from '../generated';
import currencyFormatter from '../utils/currencyFormatter';
import ProfitText from './ProfitText';
import { SwipeListView } from 'react-native-swipe-list-view';
import { useMutation } from '@apollo/client';

type Props = {
  bids?: NonNullable<MeBidsQuery['me']>['bids']['nodes'];
};

const closeRow = (rowMap: any, rowKey: any) => {
  if (rowMap[rowKey]) {
    rowMap[rowKey].closeRow();
  }
};

const MeBids = ({ bids }: Props) => {
  const navigation = useNavigation();
  const [removeBid, { loading = false, error }] = useMutation<RemoveBidMutation, RemoveBidMutationVariables>(
    RemoveBidDocument,
  );

  const handleRemoveBid = async (rowMap: any, id: string) => {
    if (rowMap[id]) {
      await removeBid({ variables: { id } });
    }
  };

  return (
    <>
      <Box w={Layout.fixWidth}>
        <HStack justifyContent="space-between">
          <VStack>
            <Text fontSize="xs" color="gray.500">
              股票代號/
            </Text>
            <Text fontSize="xs" color="gray.500">
              名稱
            </Text>
          </VStack>
          <VStack>
            <Text fontSize="xs" color="gray.500">
              持股數/
            </Text>
            <Text fontSize="xs" color="gray.500">
              平均價
            </Text>
          </VStack>

          <Text fontSize="xs" color="gray.500">
            即時股價
          </Text>
          <VStack>
            <Text fontSize="xs" color="gray.500">
              參考盈制/
            </Text>
            <Text fontSize="xs" color="gray.500">
              虧損
            </Text>
          </VStack>
        </HStack>
      </Box>
      {bids ? (
        <View style={{ height: Layout.window.height - 330 }}>
          <SwipeListView
            showsVerticalScrollIndicator={false}
            data={bids.reduce<NonNullable<MeBidsQuery['me']>['bids']['nodes']>((prev, bid) => {
              return [...prev, { ...bid, key: bid.id }];
            }, [])}
            renderItem={(
              { item: { id, stock, totalBalance, avgBuyPrice, avgSellPrice, bookProfit }, item },
              rowMap,
            ) => (
              <Pressable
                key={id}
                bg="white"
                _pressed={{ bg: 'primary.50' }}
                onPress={() => {
                  navigation.navigate('MeBid', { bidId: id });
                  closeRow(rowMap, id);
                }}
              >
                {({ isFocused, isPressed, isHovered }) => {
                  return (
                    <Box px="1" py="2" width={Layout.fixWidth} borderBottomWidth={1} borderColor="gray.200">
                      <HStack justifyContent="space-between">
                        <VStack w="20">
                          <Text color="primary.500">{stock.code}</Text>
                          <Text color="gray.600">{stock.name}</Text>
                        </VStack>
                        <VStack w="20">
                          <Text>{currencyFormatter(totalBalance)}</Text>
                          <Text>{avgBuyPrice}</Text>
                        </VStack>
                        <Text w="40px">{stock.price}</Text>
                        <VStack w="100px">
                          <ProfitText value={bookProfit} textAlign="right" />
                          {/* <Text textAlign="right">+20%</Text> */}
                        </VStack>
                      </HStack>
                    </Box>
                  );
                }}
              </Pressable>
            )}
            renderHiddenItem={({ item: { id } }, rowMap) => (
              <View style={styles.rowBack}>
                <TouchableOpacity
                  style={[styles.backRightBtn, styles.backRightBtnRight, loading && styles.loadingBtn]}
                  onPress={async () => {
                    if (!loading) {
                      await handleRemoveBid(rowMap, id);
                    }
                  }}
                >
                  <Text style={styles.backTextWhite}>{loading ? '刪除中...' : '刪除'}</Text>
                </TouchableOpacity>
              </View>
            )}
            disableRightSwipe
            rightOpenValue={-75}
          />
        </View>
      ) : (
        <Box w={Layout.fixWidth}>
          <Text size={30} w="100%" textAlign="center">
            沒有資料
          </Text>
        </Box>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  backTextWhite: {
    color: '#FFF',
  },
  loadingBtn: {
    backgroundColor: 'gray',
  },
  rowBack: {
    alignItems: 'center',
    backgroundColor: '#DDD',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
  },
  backRightBtnRight: {
    backgroundColor: '#dc2626',
    right: 0,
  },
});

export default MeBids;
