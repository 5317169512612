import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  BidAdjustmentType,
  CreateBidAdjustmentInput,
  CreateBidAdjustmentDocument,
  CreateBidAdjustmentMutation,
  CreateBidAdjustmentMutationVariables,
} from '../generated';
import {
  Box,
  Button,
  CheckIcon,
  FormControl,
  Heading,
  Input,
  Select,
  useToast,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import { Formik } from 'formik';
import { RootStackScreenProps } from '../types';
import Layout from '../constants/Layout';
import { useMutation } from '@apollo/client';
import DateTimePicker from '@react-native-community/datetimepicker';
import WebDatePicker from '../components/WebDatePicker';
import { format } from 'date-fns';
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native';
import useMeBids from '../hooks/useMeBids';

const convertBidAdjustmentTypeName = (value: BidAdjustmentType) => {
  if (value === BidAdjustmentType.BuyTradingFee) return '買入手續費';
  if (value === BidAdjustmentType.SellTradingFee) return '賣出手續費';
  if (value === BidAdjustmentType.Dividend) return '股息';
  if (value === BidAdjustmentType.Share) return '以股代息';
  return '沒有對應類別';
};

const CreateBidAdjustmentSchema: Yup.SchemaOf<CreateBidAdjustmentInput> = Yup.object().shape({
  date: Yup.string(),
  amount: Yup.number().min(1).required('請輸入款項'),
  bidId: Yup.string().required('請選擇股票'),
  type: Yup.mixed<BidAdjustmentType>().oneOf(Object.values(BidAdjustmentType)).required('請選擇類別'),
  metadata: Yup.array(),
});

const CreateBidAdjustmentScreen = ({ navigation }: RootStackScreenProps<'CreateBidAdjustment'>) => {
  const [createBidAdjustment, { data, loading, error }] = useMutation<
    CreateBidAdjustmentMutation,
    CreateBidAdjustmentMutationVariables
  >(CreateBidAdjustmentDocument);
  const { data: meBidsData, error: meBidsError, loading: meBidsLoading } = useMeBids();
  const toast = useToast();

  const [date, setDate] = useState(new Date());
  const onChange = (event: any, selectedDate: Date | undefined) => {
    const currentDate = selectedDate || date;
    setDate(currentDate);
  };
  const webOnChange = (event: any) => {
    const currentDate = event.target.value || date;
    setDate(currentDate);
  };

  useEffect(() => {
    if (error) {
      toast.show({
        title: '新增失敗，請再嘗試',
        placement: 'top',
        status: 'error',
      });
    }
  }, [error]);

  return (
    <TouchableWithoutFeedback
      onPress={() => (Platform.OS === 'ios' || Platform.OS === 'android' ? Keyboard.dismiss() : undefined)}
    >
      <Box w="100%" justifyContent="center" alignItems="center" mt="5">
        <Formik<CreateBidAdjustmentInput>
          initialValues={{
            amount: 0,
            bidId: '',
            type: BidAdjustmentType.Dividend,
          }}
          validationSchema={CreateBidAdjustmentSchema}
          onSubmit={async ({ amount, bidId, type, metadata }) => {
            try {
              await createBidAdjustment({
                variables: {
                  input: {
                    date,
                    bidId,
                    type,
                    metadata,
                    amount: parseFloat(String(amount)),
                  },
                },
              });
              toast.show({
                title: '註冊成功',
                placement: 'top',
                status: 'success',
              });
              navigation.navigate('Root');
            } catch (e) {}
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <VStack space={5} w={Layout.fixWidth}>
              <FormControl>
                <FormControl.Label>日期</FormControl.Label>
                <DateTimePicker
                  testID="dateTimePicker"
                  value={date}
                  mode="date"
                  is24Hour={true}
                  display="default"
                  onChange={onChange}
                  style={{ width: 320 }}
                />

                {Platform.OS !== 'android' && Platform.OS !== 'ios' && (
                  <WebDatePicker value={format(new Date(date), 'yyyy-MM-dd')} onChange={webOnChange} />
                )}
              </FormControl>

              <FormControl isRequired isInvalid={touched.bidId && errors.bidId ? true : false}>
                <FormControl.Label>選擇股票</FormControl.Label>
                <Select
                  minWidth="200"
                  accessibilityLabel="選擇股票"
                  placeholder="選擇股票"
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size={5} />,
                  }}
                  mt="1"
                  borderWidth={2}
                  selectedValue={values.bidId}
                  onValueChange={handleChange('bidId')}
                >
                  {meBidsData?.me?.bids.nodes.map(bid => {
                    return <Select.Item key={`${bid.id}`} label={bid.stock.name} value={bid.id} />;
                  })}
                </Select>
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.bidId}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={touched.type && errors.type ? true : false}>
                <FormControl.Label>選擇類別</FormControl.Label>
                <Select
                  minWidth="200"
                  accessibilityLabel="選擇類別"
                  placeholder="選擇類別"
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size={5} />,
                  }}
                  mt="1"
                  borderWidth={2}
                  selectedValue={values.type}
                  onValueChange={handleChange('type')}
                >
                  {Object.entries(BidAdjustmentType).map(([key, value], index) => {
                    return <Select.Item key={value} label={convertBidAdjustmentTypeName(value)} value={value} />;
                  })}
                </Select>
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.type}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={touched.amount && errors.amount ? true : false}>
                <FormControl.Label>款項</FormControl.Label>
                <Input
                  onChangeText={handleChange('amount')}
                  onBlur={handleBlur('amount')}
                  value={values.amount ? `${values.amount}` : ''}
                  keyboardType={Layout.isWebDevice ? 'default' : 'numeric'}
                  placeholder="款項"
                  w={Layout.fixWidth}
                  borderWidth={2}
                  size="md"
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  {errors.amount}
                </FormControl.ErrorMessage>
              </FormControl>

              <Button isLoading={loading} onPress={handleSubmit as any}>
                確定
              </Button>
              <Button isDisabled={loading} onPress={() => navigation.navigate('Root')} variant="outline">
                取消
              </Button>
            </VStack>
          )}
        </Formik>
      </Box>
    </TouchableWithoutFeedback>
  );
};

export default CreateBidAdjustmentScreen;
