import { useMutation } from '@apollo/client';
import React, { createContext, useState, ReactChild, ReactChildren, useEffect } from 'react';
import {
  SessionCreateDocument,
  SessionCreateMutation,
  SessionCreateMutationVariables,
  RegisterMutation,
  RegisterMutationVariables,
  RegisterDocument,
} from '../generated';
import { AuthContextData, AuthData } from '../types';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const AuthContext = createContext<AuthContextData | undefined>(undefined);

type Props = {
  children: ReactChild | ReactChildren;
};

const AuthProvider = ({ children }: Props) => {
  const [authData, setAuthData] = useState<AuthData>();
  const [login, { loading, error }] = useMutation<SessionCreateMutation, SessionCreateMutationVariables>(
    SessionCreateDocument,
  );
  const [_register, { loading: registerLoading, error: registerError }] = useMutation<
    RegisterMutation,
    RegisterMutationVariables
  >(RegisterDocument);

  useEffect(() => {
    const bootstrapAsync = async () => {
      const token = await AsyncStorage.getItem('token');
      if (!token) return;
      setAuthData({ name: 'token user nmae', email: 'token user email', token });
    };
    bootstrapAsync();
  }, []);

  const register = async ({ input }: RegisterMutationVariables) => {
    const { data } = await _register({ variables: { input } });
    if (!data?.register) return false;
    return true;
  };

  const signIn = async ({ username, password }: SessionCreateMutationVariables) => {
    const { data } = await login({
      variables: {
        username,
        password,
      },
    });

    if (!data?.sessionCreate) return false;

    await AsyncStorage.setItem('token', data.sessionCreate);
    setAuthData({ name: 'token user nmae', email: 'token user email', token: data.sessionCreate });

    return true;
  };

  const signOut = async () => {
    await AsyncStorage.clear();
    setAuthData(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        register,
        authData,
        loading: loading || registerLoading,
        error: error || registerError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
