import { Center, Skeleton, VStack } from 'native-base';
import React from 'react';

const ScreenSkeleton = () => (
  <VStack
    w="100%"
    space={8}
    overflow="hidden"
    rounded="md"
    _dark={{
      borderColor: 'coolGray.500',
    }}
    _light={{
      borderColor: 'coolGray.200',
    }}
  >
    <Skeleton h="32" />
    <Skeleton.Text />
    <Skeleton.Text width="70%" />
  </VStack>
);

export default ScreenSkeleton;
