import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import {
  Box,
  Input,
  VStack,
  Button,
  Image,
  Center,
  useToast,
  View,
  FormControl,
  WarningOutlineIcon,
} from 'native-base';
import Layout from '../constants/Layout';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStackScreenProps } from '../types';
import { SessionCreateMutationVariables } from '../generated';

const SigninSchema = Yup.object().shape({
  username: Yup.string().required('請先輸入帳號'),
  password: Yup.string().required('請先輸入密碼'),
});

const SigninScreen = ({ navigation }: RootStackScreenProps<'Signin'>) => {
  const { loading, signIn, error } = useAuth();
  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast.show({
        title: '帳號/密碼錯誤!',
        placement: 'top',
        status: 'error',
      });
    }
  }, [error]);

  return (
    <Box w="100%" h="90%" justifyContent="center" alignItems="center">
      <Formik<SessionCreateMutationVariables>
        initialValues={{
          username: 'system@888stock.app',
          password: '&UiLeo%WQqDbG*viv8Pq4sPR&BkEq9CcmB2JJKenXCo&@*BKKdCpv@cU2dTLwF*s3XG^5H',
        }}
        validationSchema={SigninSchema}
        onSubmit={async ({ username, password }) => {
          try {
            await signIn({ username, password });
          } catch (e) {}
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
          <VStack space={5} w={Layout.fixWidth}>
            <Center>
              <Image
                size={150}
                resizeMode={'contain'}
                borderRadius={100}
                source={{
                  uri: 'https://wallpaperaccess.com/full/317501.jpg',
                }}
                alt="Alternate Text"
              />
            </Center>
            <FormControl isInvalid={errors.username ? true : false}>
              <FormControl.Label>帳號</FormControl.Label>
              <Input
                placeholder="輸入帳號"
                borderWidth={2}
                size="md"
                onChangeText={handleChange('username')}
                onBlur={handleBlur('username')}
                value={values.username}
              />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {errors.username}
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.password ? true : false}>
              <FormControl.Label>密碼</FormControl.Label>
              <Input
                placeholder="輸入密碼"
                borderWidth={2}
                size="md"
                type="password"
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
                value={values.password}
              />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {errors.password}
              </FormControl.ErrorMessage>
            </FormControl>

            <Button isLoading={loading} onPress={handleSubmit as any}>
              登入
            </Button>
            <Button isDisabled={loading} onPress={() => navigation.navigate('Register')} variant="outline">
              註冊
            </Button>
          </VStack>
        )}
      </Formik>
    </Box>
  );
};

export default SigninScreen;
