import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useToast } from 'native-base';

const useToastError = (error: ApolloError | undefined) => {
  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast.show({
        title: error.message,
        placement: 'top',
        status: 'error',
      });
    }
  }, [error]);
};

export default useToastError;
